@use "../../../../../../../tokens";

.emailList {
  display: flex;
  flex-direction: column;
  gap: tokens.$spacing-lg;
  width: 100%;
}

.resendButtonWrapper {
  font: tokens.$text-body-sm;
  padding: tokens.$spacing-sm tokens.$spacing-md;

  .resendButton {
    align-items: center;
    display: flex;
    font-weight: 500;
    gap: tokens.$spacing-xs;
    margin-top: tokens.$spacing-sm;
  }
}

.addEmailButton {
  align-self: flex-end;
}
