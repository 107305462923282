@use "../../../../../../tokens";

// The attribute selector makes this more specific that <Button>'s direct styles,
// so we can overwrite the font weight. Maybe it should just not be a <Button>…
[type="button"].trigger {
  font-weight: 600;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: tokens.$spacing-xs;
  text-align: center;

  button {
    &.primaryCta {
      margin-top: tokens.$spacing-sm;
      font-weight: 600;
    }

    &.discountCta {
      background-color: tokens.$color-purple-60;
      width: 100%;

      @media screen and (min-width: tokens.$screen-sm) {
        width: tokens.$content-sm;
      }

      &:hover {
        background-color: tokens.$color-purple-70;
      }
    }

    &.tertiaryCta {
      margin-top: tokens.$spacing-lg;
      font-weight: 500;
    }
  }

  .hidden {
    display: none;
  }

  .limitationsApplyLink {
    text-decoration-color: tokens.$color-grey-30;
    .limitationsApplyText {
      display: flex;
      gap: tokens.$spacing-xs;
      align-items: center;
      justify-content: center;
      text-decoration-color: tokens.$color-grey-30;

      svg {
        width: 13px; // width of open in new tab icon
      }
    }
  }

  .errorApplyingCoupon {
    padding-top: tokens.$spacing-sm;
    color: tokens.$color-red-60;
    font-weight: 500;

    button {
      color: tokens.$color-red-60;
      font-weight: 500;
    }
  }

  small {
    color: tokens.$color-grey-30;
    font-weight: 400;
  }

  p {
    max-width: 600px;
  }
}

.goToDashboardCta {
  margin-top: tokens.$spacing-lg;
  font: tokens.$text-body-md;
  font-weight: 500;
  border: 0;
  padding: tokens.$spacing-md tokens.$spacing-lg;
  border-radius: tokens.$border-radius-md;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  color: tokens.$color-white;
  background-color: tokens.$color-blue-50;

  &:hover {
    background-color: tokens.$color-blue-60;
  }

  &:focus {
    outline: tokens.$border-focus-width solid tokens.$color-blue-30;
  }
}

.cancellationAnimation {
  width: 250px; // width of animation
}

.cancellationIllustrationWrapper {
  height: 200px; // height of illustration;
  width: 100%;
  padding-bottom: tokens.$spacing-lg;

  img {
    height: 100%;
  }
}

.staticAlternative {
  display: none;
}

.iframeWrapper {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  height: 280px; //height of iframe
}

@media (min-width: tokens.$content-lg) {
  .iframeWrapper {
    height: 260px;
  }
}

@media (prefers-reduced-motion) {
  .cancellationAnimation {
    display: none;
  }
  // Ideally a `display: none` on <source> elements would be enough to
  // cause the <img> inside <video> to be displayed, but it's not.
  // Hence, another <img> element:
  .staticAlternative {
    display: inline-block;
  }
}
